function minHeight(list, content) {
  var $panelContent = list.find(".list-filter > .panel > .panel-content");
  var height = 0;
  if ($panelContent.length > 0) {
    height = $panelContent.height();
  }

  content.css("min-height", height + "px");
}

$(".list").each(function () {
  var list = $(this);
  var hiddenInput = list.find('[name^="filter"]');
  var content = list.find(".list-content");
  var formSetter = list.find("a[data-form-name]");
  var listFilter = list.find(".list-filter-form-items");
  var minHeightHandler = function () {
    minHeight(list, content);
  };
  if (hiddenInput.attr("value") === "open") {
    list.addClass("with-filter");
  }

  list.on(
    "transitionend webkitTransitionEnd oTransitionEnd otransitionend",
    minHeightHandler
  );
  $(document).on("layout-changed", minHeightHandler);
  $(document).on("load", minHeight(list, content));

  list
    .find(".list-filter > .panel")
    .on("panel-on", function () {
      list.addClass("with-filter");
      hiddenInput.attr("value", "open");
    })
    .on("panel-off", function () {
      list.removeClass("with-filter");
      hiddenInput.attr("value", "closed");
    });

  formSetter.on("click", function (ev) {
    ev.preventDefault();

    var setter = $(this);

    var form = $('form[name="' + setter.data("form") + '"]');

    form
      .find('[name="' + setter.data("form-name") + '"]')
      .val([setter.data("form-value")]);

    form.submit();
  });

  list.find(".list-filter button[type=reset]").on("click", function (ev) {
    ev.preventDefault();
    
    list.find(".list-filter input, .list-filter select, .list-filter textarea").each(function () {
      if ($(this).is(":checkbox") || $(this).is(":radio")) {
        $(this).prop("checked", false);
      } else {
        $(this).val("");
      }
    });

    hiddenInput.attr("value", "closed");
    list.removeClass("with-filter");
  });
});
